import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Wrap } from "./wrap"
import { colors, spacers } from "../theme"
import { sm, md } from "../utils"

const FooterWrapper = styled.footer`
  padding: ${spacers.md} 0;
  margin-top: ${spacers.lg};
  background-color: ${colors.brandBlue};
  color: ${colors.white};
`

const FooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${md("justify-content: space-between;")}
`

const FooterItem = styled.div`
  width: 100%;
  text-align: center;

  :not(:last-child) {
    padding-right: ${spacers.md};
    ${md("text-align: left;")}
  }

  :last-child {
    ${md("text-align: right;")}
  }

  ${md("width: 50%;")}
`

const FooterText = styled.div`
  display: inline-block;
  padding-bottom: ${spacers.sm};
`

const BrandImageContainer = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;

  ${sm(`
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  `)}
`

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Wrap>
        <FooterInner>
          <FooterItem>
            <FooterText>
              Enzyme Software Limited is a registered company in England & Wales
              with number 11245392.
            </FooterText>
            <FooterText>
              Registered address: The Old Bakery, 90 Camden Road, Tunbridge
              Wells, TN1 2QP
            </FooterText>
            <FooterText>
              Copyright © {new Date().getFullYear()} Enzyme Software Limited
            </FooterText>
          </FooterItem>
          <FooterItem>
            <BrandImageContainer>
              <Img
                fluid={data.image.childImageSharp.fluid}
                alt="enzyme software logo"
              />
            </BrandImageContainer>
          </FooterItem>
        </FooterInner>
      </Wrap>
    </FooterWrapper>
  )
}
