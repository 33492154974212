import React from "react"
import styled from "styled-components"
import { Section, SectionHeading } from "./section"
import { colors, spacers } from "../theme"
import { md } from "../utils"
import BuildIcon from "../icons/fluff-build.svg"
import CloudIcon from "../icons/fluff-cloud.svg"
import SpeedIcon from "../icons/fluff-speed.svg"

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: -${spacers.lg};
  margin-left: -${spacers.lg};
`

const ListItem = styled.li`
  width: 100%;
  padding-top: ${spacers.lg};
  padding-left: ${spacers.lg};

  ${md(`width: 33.33%;`)}
`

const Card = styled.div`
  height: 100%;
  padding: ${spacers.lg};
  box-shadow: 0 3px 8px 0px ${colors.boxShadow};
  border-radius: 8px;
`

const CardHeading = styled.h3`
  text-align: center;
`

const CardIconWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: ${spacers.md};
  width: 48px;
  height: 48px;
`

export const WhatWeDo = () => {
  return (
    <Section>
      <SectionHeading>What we do</SectionHeading>
      <List>
        <ListItem>
          <Card>
            <CardIconWrapper>
              <BuildIcon />
            </CardIconWrapper>
            <CardHeading>Quality software</CardHeading>
            <span>
              We specialise in architecting and developing complex applications
              in a secure way using the latest web technologies such as React
              and TypeScript and development practices including Test Driven
              Development.
            </span>
          </Card>
        </ListItem>
        <ListItem>
          <Card>
            <CardIconWrapper>
              <SpeedIcon />
            </CardIconWrapper>
            <CardHeading>Fast delivery</CardHeading>
            <span>
              We strive to deliver value to your customers as quickly as
              possible. To achieve this we are experts at architecting and
              implementing continuous integration and continuous delivery using
              the latest providers such as GitHub Actions, Jenkins, AppVeyor
              etc.
            </span>
          </Card>
        </ListItem>
        <ListItem>
          <Card>
            <CardIconWrapper>
              <CloudIcon />
            </CardIconWrapper>
            <CardHeading>Modern ops</CardHeading>
            <span>
              We have experience orchestrating the latest cloud technologies
              such as docker and serverless in order to keep your stack cutting
              edge and allow it to be deployed and scale as simply as possible.
            </span>
          </Card>
        </ListItem>
      </List>
    </Section>
  )
}
