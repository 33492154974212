import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Section } from "./section"
import { colors, spacers, typography } from "../theme"
import { sm, md } from "../utils"

const HeroWrapper = styled.div`
  position: relative;
`

const HeroMessageContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 15%;
  padding: ${spacers.md};
  background-color: rgba(0, 123, 189, 0.7);
`

const HeroHeading = styled.h1`
  padding-bottom: ${spacers.sm};
  color: ${colors.white};

  ${sm(`padding-bottom: ${spacers.md}`)}
`

const HeroMessage = styled.span`
  display: block;
  color: ${colors.white};
  font-size: ${typography.fontSizes.sm};
  font-weight: 600;

  ${md(`
    font-size: ${typography.fontSizes.md};
  `)}
`

export const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section>
      <HeroWrapper>
        <Img fluid={data.image.childImageSharp.fluid} />
        <HeroMessageContainer>
          <HeroHeading>Enzyme Software</HeroHeading>
          <HeroMessage>
            Software development consultancy focused on unlocking your potential
          </HeroMessage>
        </HeroMessageContainer>
      </HeroWrapper>
    </Section>
  )
}
