import React from "react"
import styled from "styled-components"
import { Section, SectionHeading } from "./section"
import { colors, spacers } from "../theme"
import { sm } from "../utils"
import GitHubIcon from "../icons/social-github.svg"
import LinkedInIcon from "../icons/social-linkedin.svg"
import TwitterIcon from "../icons/social-twitter.svg"

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: -${spacers.xl};
  margin-left: -${spacers.xl};
`

const ListItem = styled.li`
  padding-top: ${spacers.xl};
  padding-left: ${spacers.xl};
`

const SalesEmailWrapper = styled.div`
  padding-bottom: ${spacers.xl};
`

const SalesEmailLink = styled.a`
  font-weight: 600;
  color: ${colors.brandBlue};
`

const IconLink = styled.a`
  display: block;
  width: 48px;
  height: 48px;

  ${sm(`
    width: 64px;
    height: 64px;
  `)}
`

export const Sales = () => {
  return (
    <Section>
      <SectionHeading>Get in touch</SectionHeading>
      <SalesEmailWrapper>
        <SalesEmailLink
          href="mailto:sales@enzymesoftware.co.uk"
          aria-label="sales email"
        >
          sales@enzymesoftware.co.uk
        </SalesEmailLink>
      </SalesEmailWrapper>
      <List>
        <ListItem>
          <IconLink href="https://github.com/enzsft" aria-label="github">
            <GitHubIcon />
          </IconLink>
        </ListItem>
        <ListItem>
          <IconLink
            href="https://www.linkedin.com/in/lee-cheneler-4739b349/"
            aria-label="linked in"
          >
            <LinkedInIcon />
          </IconLink>
        </ListItem>
        <ListItem>
          <IconLink href="https://twitter.com/enzsft" aria-label="twitter">
            <TwitterIcon />
          </IconLink>
        </ListItem>
      </List>
    </Section>
  )
}
