import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Hero } from "../components/hero"
import { Wrap } from "../components/wrap"
import { WhoWeAre } from "../components/who-we-are"
import { WhatWeDo } from "../components/what-we-do"
import { Technologies } from "../components/technologies"
import { Sales } from "../components/sales"
import { Footer } from "../components/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Wrap noPaddingOnMobile>
      <Hero />
    </Wrap>
    <Wrap>
      <WhoWeAre />
      <WhatWeDo />
      <Technologies />
      <Sales />
    </Wrap>
    <Footer />
  </Layout>
)

export default IndexPage
