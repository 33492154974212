import React from "react"
import styled from "styled-components"
import { Section, SectionHeading } from "./section"
import { spacers } from "../theme"
import { sm, md } from "../utils"
import AwsIcon from "../icons/tech-aws.svg"
import CSharpIcon from "../icons/tech-csharp.svg"
import GitIcon from "../icons/tech-git.svg"
import JavascriptIcon from "../icons/tech-javascript.svg"
import TypescriptIcon from "../icons/tech-typescript.svg"
import ReactIcon from "../icons/tech-react.svg"
import WebpackIcon from "../icons/tech-webpack.svg"
import DockerIcon from "../icons/tech-docker.svg"

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: -${spacers.xl};
  margin-left: -${spacers.xl};
`

const ListItem = styled.li`
  width: 33.33%;
  padding-top: ${spacers.xl};
  padding-left: ${spacers.xl};

  ${sm(`width: 20%;`)}
  ${md(`width: 15%;`)}
`

const FinalItem = styled.span`
  font-weight: 600;
`

export const Technologies = () => {
  return (
    <Section>
      <SectionHeading>What we use</SectionHeading>
      <List>
        <ListItem>
          <JavascriptIcon />
        </ListItem>
        <ListItem>
          <TypescriptIcon />
        </ListItem>
        <ListItem>
          <ReactIcon />
        </ListItem>
        <ListItem>
          <WebpackIcon />
        </ListItem>
        <ListItem>
          <DockerIcon />
        </ListItem>
        <ListItem>
          <AwsIcon />
        </ListItem>
        <ListItem>
          <GitIcon />
        </ListItem>
        <ListItem>
          <CSharpIcon />
        </ListItem>
        <ListItem>
          <FinalItem>And many more...</FinalItem>
        </ListItem>
      </List>
    </Section>
  )
}
