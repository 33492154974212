import React from "react"
import { Section, SectionHeading } from "./section"

export const WhoWeAre = () => {
  return (
    <Section>
      <SectionHeading>Who we are</SectionHeading>
      <p>
        Enzyme Software was formed in 2018 with a desire to help as many
        companies as possible delight their customers through software. Since
        forming we have helped businesses, small and very large, modernise their
        web presence, improve their development and testing practices and
        facilitated a large amount of automation.
      </p>
      <span>
        We firmly believe in bringing our customer along for the journey too. We
        enjoy a collaborative and fast paced development environment that
        focuses on delivering customer value. We're happy to work with our
        customers developers to ensure that they are filling in any knowledge
        gaps and fully understand the systems and applications that are built.
      </span>
    </Section>
  )
}
